.App {
  text-align: center;
}

.main-background{
  background-image: url('./commonstyle/images/Trashap-Background-image.jpg') !important;
  min-height: 100vh;
  overflow:auto;
  background-size: cover;
  background-attachment: fixed;
}

.main-background-with-can{
  background-image: url('./commonstyle/images/Trashap_background_with_can.png') !important;
  min-height: 100vh;
  overflow:auto;
  background-size: cover;
  background-attachment: fixed;
}

@media screen and (max-width: 576px){
  .main-background-with-can{
    background-image: url('./commonstyle/images/Trashap-Background-image.jpg') !important;
    min-height: 100vh;
    overflow:auto;
    background-size: cover;
    background-attachment: fixed;
  }
}

.center-screen {
  position: fixed;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hover-effect:hover {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
