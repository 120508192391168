.dashboard-main-container{
    padding-bottom: 50px;
    padding-left: 70px;
    padding-right: 70px;
}

/* @media screen and (min-width: 1025px) {
    .dashboard-main-container{
        width: 1140px;
        margin: auto;
    }
} */
.font-head{
    font-family: "Avenir-LT-Std-65-Medium";
}
.font-para{
    font-family: "Avenir-LT-Std-45-Book";
}

h1, h2, h3, h4, h5, h6, label{
    font-family: "Avenir-LT-Std-65-Medium";
    opacity: 1;
}
p, small, input {
    font-family: "Avenir-LT-Std-45-Book";
    opacity: 1;
}
.font1{
    font-size: 35px;
}
.font2{
    font-size: 22px;
}
.font3{
    font-size: 20px;
}
.font4{
    font-size: 16px;
}
.font5{
    font-size: 17px;
}
.font6{
    font-size: 16px;
}
.font7{
    font-size: 15px;
}
.font8{
    font-size: 14px;
}
.font9{
    font-size: 12px;
}

.info-icon{
    width: 16px;
    height: 12px;
}

.left-container{
    /* min-height: 65vh; */
}
.title-color{
    color: #db5500;
}
.dashboard-image{
    width: 100%;
    max-height: 100%;
    height: 100px;
    object-fit: cover;
    max-width: none;
    /* height: 15vh; */
}
.fixer-dashboard-image{
    width: 100%;
    max-height: 100%;
    height: 94px;
    object-fit: cover;
    max-width: none;
    /* height: 15vh; */
}
.dashboard-trashie{
    height: 21%;
    align-items: center;
}

.trash-icon{
    width: 45px;
    height: 49px;
}
.right-container{
    /* width: 49%;
    min-height: 65vh; */
}
.map-container{
    /* width: 100%;
    min-height: 36vh; */
}
.action-container{
    /* width: 100%;
    min-height: 30vh; */
    /* min-height: 48%; */
}
.shape{
    width: 60px;
    height: 60px;
    /* background-color:peachpuff; */
    background-color:#FFEDEB;
    /* margin-left: 7px; */
}

.left-shape {
    width: 25px;
    height: 25px;
    background-color: #db5500;
    border-radius: 30%!important;
}

.addIcon {
    width: 25px;
    height: 27px;
    margin: 18px;
}
.pending{
    padding: 0px 135px 15px 60px;
}
.over{
    padding: 0px 100px 15px 50px;
}
/* .footer{
    min-height: 15vh;
    bottom: 6px;
} */
.count-box{
    padding-bottom: 28px;
}
.assign-status{
    justify-content: center;
}
.cursor {
    cursor: pointer;
}

.actions{
    height: 21px;
}
.action{
    height: 21px;
    padding: 0px 3px 0px 3px;
}
#loading-icon {
    position: absolute;
    float: left;
    top:50%;
    left:0;
    right: 0;
    text-align: center;
    margin: auto;
  }

.in-progress{
    background-color: #db5500;
}
.closed{
    background-color:#169c02;
}
.prince{
    background-color: #6610f2;
}
.fixed{
    background-color: #7FFFD4;
}
.approved{
    background-color: chartreuse;
}
.dashboard-addImage {
    width: 16px;
    height: 20px;
}
.dashboard-locationImage {
    width: 16px;
    height: 24px;
}
.dashboard-bottom-box{
    /* height: 16vh; */
}
.count-boxes{
    /* height: 14vh; */
    margin-top: 4px;
}
.box-height{
    margin-top: 10px;
}
.graph-part{
    margin-top: 8px;
}
.more-part{
    margin-top: 10px;
    font-size: 14px;
}
.fixer-more{
    margin-top: 4px;
    font-size: 14px;
}
.bottom-part-trashie{
    margin-top: 8px;
}
.fixer-count{
    height: 16vh;
    margin-top: 30px;
}
.fixer-graph{
    margin-top: 12px;
}


@media screen and (max-width: 768px){
    .left-shape {
        width: 20px;
        height: 20px;
        background-color: #db5500;
        border-radius: 30%!important;
    }
    .dash-desc{
        margin-bottom: 0px !important;
    }
    .login-title{
        margin-top: 0px !important;
    }
    .bottom-space{
        margin-bottom: 0.5rem !important;
    }
    .moreInfoIcon{
        width: 13px !important;
        height: 13px !important;
    }
    .marginTop{
        margin: 10px 0px 0px 0px !important;
        justify-content:flex-start !important;
        padding: 0px !important;
    }
    .marginTopButton{
        margin: 10px 0px 0px 0px !important;
        justify-content: flex-end !important;
        padding: 0px !important;
    }
    .padd-none{
        padding: 0px !important;
    }
    .padding-left-status{
        padding-left: 1rem !important;
    }
    .more-part{
        margin-top: 10px;
        margin-right: 0px !important;
        font-size: 14px;
    }
    .dashboard-head{
        padding-left: 0px !important;
    }
    .margin-none{
        margin: 0px !important;
    }
    .margin-left-space{
        margin-left: 28px !important;
    }
    .width-10{
        width: 100% !important;
    }

}

@media screen and (min-width:230px) and (max-width: 315px){
    .dash-icon-fixer{
        margin-right: 0px !important;
    }
    .status-button-width{
        max-width: 50% !important;
        flex: auto;
    }
    .no-left-padding{
        padding-left: 0px !important;
    }
}
@media screen and (min-width:768px) and (max-width: 817px){
    .date{
        margin: 4px 0 0 6px;
    }
}

@media screen and (min-width:1110px) and (max-width: 1276px){
    .unassign-left{
        padding-left: 0px !important;
    }
}
@media screen and (min-width:992px) and (max-width: 1100px){
    .marginTopButton {
        padding-left: 1rem !important;
        margin-left: 0.25rem;
    }
}
@media screen and (min-width:992px) and (max-width: 1122px){
    .fixer-count {
        height: 20vh;
        margin-top: 34px;
    }
}

@media screen and (min-width:992px) and (max-width: 1150px){
    .margin-remove{
        margin-left: 0px !important;
    }
}
@media screen and (min-width:768px) and (max-width: 1110px){
    .defined-width{
        max-width: 100% !important;
        flex: none;
    }
    .dashboard-right-head{
        padding-left: 0px !important;
    }
}

@media screen and (min-width: 1980px){
    .box-height{
        margin-top: 45px;
    }
    .count-boxes{
        height: 13vh;
        margin-top: 24px;
    }
    .graph-part{
        margin-top: 18px;
    }
    .more-part{
        margin-top: 32px;
        font-size: 14px;
    }
    .bottom-part-trashie{
        margin-top: 17px;
    }
    .icon{
        flex-flow: row-reverse;
        margin-top: 28px !important;
    }
    .fixer-count{
        height: 13vh;
        margin-top: 42px;
    }
    .fixer-graph{
        margin-top: 28px;
    }
    .fixer-more{
        margin-top: 32px;
        font-size: 14px;
    }
}


.image-box{
        overflow: hidden;
        /* height: 100%; */
        /* display: flex; */
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 0px;
}
.back-clr{
    background-color: #EA3114;
}




.dash-main {
    width: 100%;
    float: left;
    padding: 3% 3%;
}

.dashboard-icon:first-child {
    margin-left: 0px;
}

.dashboard-main-container p{
    font-size: 13px;
}

.dashboard-container{
    width: 100%;
}

.dashboard-main-container
.dashboard-welcome{
    font-size: 30px;
    font-family: "BrandonGrotesque-Bold";
    color: #3d3d3d;
}

.dashboard-icon-container{
    width: 100%;
    padding: 50px 0px 50px 0px;
    float: left;
}

.dashboard-icon{
    /* height: 200px; */
    width: 135px;
    margin: 0 10px;
    background: #fff;
    float: left;
    height: 175px;
    margin-bottom: 20px;
}

.dashboard-icon-align{
    padding: 20px 20px;
}

/* .dashboard-icon-image{
    background-color: transparent;
    border: 0px solid transparent;
    background-repeat: no-repeat;
    background-size: contain;
    width: 86%;
    height: 85px;
    background-position: center center;
    text-align: center;
    margin: auto;
} */


/* .dashboard-icon-bootom{
    width: 100%;
    float: left;
    text-align: center;
    font-weight: 500;
    padding: 15px 5px 20px 5px;
    background: rgb(202, 202, 202);
}

.dashboard-icon-bootom
.dashboard-icon-text{
    font-size: 11px;
    color: rgb(63, 63, 63);
    font-family: "BrandonGrotesque-Bold";
}

.dashboard-active-icon-container
.dashboard-icon-bootom{
    background: rgb(194, 2, 2);

}
.dashboard-active-icon-container
.dashboard-icon-text{
    color: white;
} */

/* .dashboard-bottom-container{
    width: 100%;
    height:100px;
    float: left;
    font-size: 16px;
} */

/* .dashboard-btn-container{
    padding: 30px 0px;
    width: 100%;
    float: left;
} */
/*
a .dashboard-btn{
    width: 40%;
    font-size: 12px;
    text-align: left;
    padding: 5px 10px 5px 40px;
    height: 45px;
    border: none;
    color: white;
    font-weight: 600;
    background:  rgb(194, 2, 2);
    font-family: "BrandonGrotesque-Bold";
    letter-spacing: 1px;
} */

/* a .dashboard-btn{
    list-style: none;
    text-decoration: none;
    color: white;
}
.popup_body .customerList {
    width: 50%;
    background: #fff;
    padding: 20px 0 0;
    margin-left: 25%;
}
.customerList p {
    background: #ac0101;
    padding: 8px 0;
    margin: 0;
    color: #fff;
    width: 100%;
    font-size: 12px;
    cursor: pointer;
} */


@media screen and (min-width: 1025px) {
    .dashboard-container {
        width: 1085px;
        margin: auto;
    }
}

.count-boxes .count-box-text{
    padding: 7px 0px;
    /* margin: inherit; */
}
.fixer-count .count-box-text{
    margin: inherit;
}

.width-100{
    width: 100%;
}

.width-90{
    width: 90%;
}

.width-10{
    width: 9%;
}

.googleButtons{
    background-color: #db5500;
    border: #db5500;
    margin-left: 5px;
}
.googleButtons:disabled {
    background-color: #d97030;
    border: gray;
    color: #fff;
}
.googleButtons:hover {
    background-color: #cc5a01;
    border: #cc5a01;
}
.googleButtons:enabled {
    background-color: #db5500;
    border: #db5500;
}
.googleButtons:focus {
    background-color: #db5500;
    border: #db5500;
}
