
.box {
    overflow: hidden;
    min-height: 85vh;
    margin: auto;
}
.infinite-scroll-component{
    overflow:  visible !important;
}
.detail-box{
    overflow: auto;
    min-height: 60vh;
    margin: auto;
}
.title-color{
    color: #db5500;
}
.main-box{
    padding: 20px 70px 50px 70px;
    min-height: 94vh;
}
#loading-icon {
    position: absolute;
    float: left;
    top:50%;
    left:0;
    right: 0;
    text-align: center;
    margin: auto;
  }

#myInput{
    background-image: url('../Trashies/search.png');
    background-position: 2px 2px;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    width: 70%;
    font-size: 12px;
    padding: 4px 4px 4px 25px;
    border: 1px solid #db5500;
    border-radius: 7px;
    float: right;
    margin-bottom: 16px;
}
.image{
    /* width: 250px; */
    height: 20vh;
    object-fit: cover;
}
.status-color-round{
    width: 15px;
    height: 15px;
    position: relative;
    bottom: 28px;
    left: 5px;
}
.icon{
    flex-flow: row-reverse;
    margin-top: 4px;
}
.addImage {
    width: 25px;
    height: 31px;
}
.locationImage {
    width: 26px;
    height: 20px;
}
.small{
    margin-bottom: 0.5rem;
}
.card{
    width: 25%;
    padding: 11px;
    min-height: 35vh;
    overflow: visible;
    margin-left: 6.5%;
}
.nav-link{
    inline-size: max-content;
}

.form-allign-popup{
    padding-right: 9px;
}
.count{
    padding-top: 2px;
    width: 22px;
    color: white;
    float:right;
}
.date{
    margin: 4px 0px 0px 35px;
}
.status{
    width: 15px;
    height: 15px;
}
.trash-image{
    width: 100%;
    height: 38vh;
    object-fit: cover;
    border: 1px solid lightgrey;
}
.trash-map{
    width: 100%;
    height: 38vh;
}
.dashboard-map{
    width: 100%;
    height: 30vh; 
}
.profile-image {
    width: 75px;
    height: 70px;
}
.status-roll{
    width: 20px;
    height: 20px;
    border-radius: 50%!important;
}
.status-color{
    width: 20px;
    height: 20px;
    border-radius: 50%!important;
    margin-right: 1rem!important; 
}
.First-text:first-letter{
    text-transform:capitalize;
}
.task{
    /* width: 130px; */
    height: 26px;
}
.align-left{
    justify-content: flex-end;
}
.info{
    height: 26px;
}
.assigned {
    padding-left: 75px;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.5;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}
.print-trash-image{
    width: 70%;
    height: 90vh;
}


  @page {
    /* size: auto; */
    margin-top: 15mm;
    margin-bottom: 15mm;
    margin-left: 10mm;
    margin-right: 10mm;
  }

.lable-name{
    width: 25%;
}
.data-name{
    width: 70%;
}
.pdf-print{
    margin: 25px;
}


.button-allign{
    /* position: absolute; */
    /* bottom: 6px; */
    justify-content: flex-end;
}
.addImages {
    width: 28px;
    height: 30px;
}
.fixerIcon {
    width: 27px;
    height: 28px;
}
.button-allignment{
    position: absolute;
    bottom: -10px;
    right: 12px;
}
.button-allign-dashboard{
    position: absolute;
    bottom: 24px;
    right: 12px;
}
.taskLocation{
    width: 27px;
    height: 29px;
}
.TaskStatus{
    width: 36px;
    height: 40px;
    margin-top: -4px;
}
.moreInfoIcon{
    width: 17px;
    height: 17px;
}
.ButtonIcon{
    width: 21px;
    height: 21px;
}

.button-shape {
    width: 28px;
    height: 28px;
    background-color: #db5500;
    border-radius: 30%!important;
}
.align-button{
    left: 30px;
}
.content{
    max-width: 1440px;
    margin: auto;
}
.info-button{
    width: 110px;
    height: 30px;
}
.image-full-screen-style{
    width: 100%;
}
.close-btn{
    position: absolute;
    right: -30px;
    top: -30px;
    font-size: 36px;
    font-family: 'Avenir-LT-Std-45-Book';
    padding: 0px !important;
    color: #fff;
    text-shadow: 0 0.5px 0 #fff;
}
.detail-right {
    margin-left: 30px;
}
.backbutton{
    width: 20px;
}
@media screen and (min-width: 768px) and (max-width: 992px){
    .card{
        width: 100%;
        padding: 11px;
        min-height: 35vh;
        overflow: visible;
        margin-left: 6.5% !important;
    }
    .date{
        margin: 4px 0px 0px 28px;
    }
    .ck.ck-editor {
        position: relative;
        width: 81% !important;
    }
    
}

@media screen and (min-width: 351px) and (max-width: 381px){
    .small-device{
        max-width: 100% !important;
        margin-left: 2px !important;
    }
}
@media screen and (max-width: 350px){
    .small-device{
        max-width: 100% !important;
        margin-left: 0px !important;
    }
}

@media screen and (min-width: 576px) and (max-width: 725px){
    .button-width{
        max-width: 100% !important;
        flex: none;
    }
}

@media screen and (min-width: 992px) and (max-width: 1108px){
    .button-width{
        max-width: 100% !important;
        flex: none;
    }
}

@media screen and (min-width: 576px) and (max-width: 768px){
    .flex-small-none{
        flex: none !important;
    }
    .card {
        width: 100%;
        padding: 11px;
        min-height: 35vh;
        overflow: visible;
        margin-left: 6%!important;
    }
    #myInput {
        background-image: url('../Trashies/search.png');
        background-position: 2px 2px;
        background-size: 14px 20px;
        background-repeat: no-repeat;
        width: 100%;
        font-size: 12px;
        padding: 4px 4px 4px 25px;
        border: 1px solid #db5500;
        border-radius: 7px;
        float: right;
        margin-bottom: 16px;
    }
    .date{
        margin: 4px 0px 0px 28px;
    }
    .ck.ck-editor {
        position: relative;
        width: 100% !important;
    }
    .border-none{
        border-bottom: 1px solid #dee2e6;
        margin-bottom: 16px;
        border-right: none !important;
    }
}

@media screen and (max-width: 576px){
    .flex-small-none{
        flex: none !important;
    }
    .card {
        width: 100%;
        padding: 11px;
        min-height: 35vh;
        overflow: visible;
        margin-left: 1.5% !important;
    }
    .date{
        /* padding-left: 0!important;
        margin-left: 0px;
        margin-right: 14%; */
        margin: 0px !important;
        width: 100% !important;
    }
    .detailpage-title{
        text-align: center;
        margin-left: 0px !important;
    }
    .fixer-card {
        margin-left: auto!important;
    }
    .ck.ck-editor {
        position: relative;
        width: 100% !important;
    }
    .border-none{
        border-bottom: 1px solid #dee2e6;
        margin-bottom: 16px;
        border-right: none !important;
    }
}

@media screen and (max-width: 425px){
    .iconstyle {
        left: 60% !important;
    }
}

@media screen and (max-width: 992px){
    #myInput{
        width: 100%;
        float: none;
    }
    .font8{
        font-size: 16px !important;
    }
    .dashboard-trashie{
        height: auto !important;
        align-items: center;
    }
    .dashboard-main-container{
        padding-bottom: 50px;
        padding-left: 14px;
        padding-right: 14px;
    }
    
    .main-box {
        padding: 20px 14px 20px 14px !important;
        min-height: 94vh;
    }
    .iconstyle {
        left: 20%;
    }
    .fixer-create-box{
        margin-bottom: 10%;
    }
    .status-color {
        margin-right: 0.5rem!important;
    }
    .align-left{
        justify-content: flex-start !important;
    }
    .locationImage {
        width: 20px;
        height: 20px;
    }
    .right-space{
        margin-right: 20% !important;
    }
    .margin-left-none{
        margin-left: 0px !important;
    }
    .status-color{
        width: 16px;
        height: 16px;
        margin-top: 1px;
    }
    .status-part{
        margin-top: 10px;
        margin-right: 0!important;
        width: 100%;
        padding-left: 15px;
    }
    .trashcan-left{
        margin-left: 2% ;
    }
    .width-nav{
        min-width: 50% !important;
        border-bottom: 0.5px solid #e6e6e6;
    }
    .width-link{
        min-width: 100% !important;
    }
    .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
        border-color: lightgrey;
        background-color: ghostwhite;
    }
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
        color: #495057;
        border-color: lightgrey;
        background-color: ghostwhite;
    }
}