.bar-chart-container {
    display: flex;
    flex-direction: column;
}

.bar-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.bar {
    position: relative;
    width: 200px;
    height: 20px;
    background-color: #b8b8b8;
    margin-right: 10px;
}

.filled-bar {
    position: absolute;
    height: 100%;
    background-color: #cc5a01;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bar-text{
    color: white;
}

.description {
    flex: 1;
    text-align: left;
}

.recyclability {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.shape2 {
    width: 30px;
    height: 30px;
    margin-left: 10px;
}
