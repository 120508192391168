/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "BrandonGrotesque-Bold";
  src: url('fonts/BrandonGrotesque-Bold.ttf');
}
@font-face {
  font-family: "Avenir-LT-Std-65-Medium";
  src: url('fonts/Avenir-LT-Std-65-Medium.ttf');
}
@font-face {
  font-family: "Avenir-LT-Std-45-Book";
  src: url('fonts/Avenir-LT-Std-45-Book.ttf');
}

body {
  font-family: 'Open Sans', sans-serif;
}

.headfont {
  font-family: "BrandonGrotesque-Bold";
}


.mainpagetitle {
  width: 100%;
  float: left;
  padding: 0 !important;
  margin-top: 15px;
}

.commonWidth {
  width: 100%;
  margin: auto;
}

@media screen and (min-width: 1140px) and (max-width: 1280px) {
  .commonWidth {
    max-width: 1140px;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1440px) {
  .commonWidth {
    max-width: 1280px;
  }
}

@media screen and (min-width: 1441px) {
  .commonWidth {
    max-width: 1440px;
  }
}


