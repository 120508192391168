.image-shape{
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.addImage {
  width: 25px;
  height: 31px;
}
.camera{
  margin-left: 7%;
}
.iconstyle {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  overflow: hidden;
  background: #db5500;
  position: relative;
  bottom: 32px;
  padding: 6px;
  left: 20%;
}
.title-color{
  color: #db5500;
}
.input-border{
  border: 1px solid #db5500 !important; 
  background-color: #FAFAFA;
}
