.head-sect {
    background-color: #db5500;
    border: #db5500;
    color: white;
}
.react-button:hover{
    background-color: #db5500;
    border: #db5500;
    color: black;
}
.trashap-logo{
    width: 136px;
    height: 45px;
    /* height: 36px; */
    margin: 6px;
    /* margin: 9px 6px 0px 6px; */
    padding-left: 10px;
}
.nav-icon {
    width: 16px;
    height: 16px;
}
/* .nav-icon1 {
    width: 23px;
    height: 20px;
}
.nav-icon2 {
    width: 20px;
    height: 20px;
}
.nav-icon3 {
    width: 16px;
    height: 20px;
}
.nav-icon4 {
    width: 20px;
    height: 16px;
} */
.font-menu{
    font-family: "Avenir-LT-Std-65-Medium";
    opacity: 1;
    font-size: 20px;
}
.actives {
    text-underline-offset: 4px;
    text-decoration: underline;
    font-weight: bolder;
    color: white;
    cursor: pointer;
}
.inactive {
    font-weight: lighter;
    color: rgba(236, 232, 232, 0.952);
    cursor: pointer;
}
/* .language p.active:before {
    content: '';
    background-image: url('../commonstyle/images/active.svg');
    position: absolute;
    width: 30px;
    height: 75px;
    background-repeat: no-repeat;
    background-size: 24px 82px;
} */

@media screen and (max-width: 991px){

.navbar-toggler-icon{
    background: url('../commonstyle/images/humburger.png');
    background-repeat: repeat;
    background-size: auto;
    height: 24px;
    display: inline-block;
    width: 30px;
    color: transparent;
    background-repeat: no-repeat;
    background-size: 25px 20px;
    margin: 6% auto;
}
.dropdown-mobile{
    position: absolute;
    top: 99%;
    background-color: #db5500;
    z-index: 999;
    left: 0%;
    right: 0%;
}
.left-nav-space{
    padding-left: 25px;
    padding-top: 10px;
}
.trashap-logo {
    width: 136px;
    height: 45px;
    margin: 6px 0px 6px 0px;
    padding-left: 0px;
}
.dropdown-nav-item{
    margin-bottom: 6px !important;
}
.lang-space{
    padding-right: 6px !important;
}
}

.noHover{
    pointer-events: none;
}
.nav-link{
    font-size: 15px;
}
